@import "styles/colors.scss";
@import "styles/mixins.scss";

.footer {
	background: $dark;
	color: #fff;

   a { color: #fff; }
}

.footer-top {
   @include flex-centered;
   flex-wrap: wrap;
   gap: 10px 15px;
   padding: 10px 15px;
}

.footer-bottom {
	@include flex-centered;

	height: auto;
	padding: 10px 0 7px;
	
	span {
		margin-right: 22px;
	}

	> div {
		svg:last-child {
			margin-left: 9px;
		}
	}
}

body.contrast {	
	.footer {
		color: $yellow;
	}

	.footer-bottom {
		background: $black;
	}
}


@media (max-width: 600px) {
	.footer-bottom span {
		font-size: 11px;
		margin-right: 12px;
	}
}